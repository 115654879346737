import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { Component, OnInit, ɵConsole, Input } from '@angular/core';
import { HostListener } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Location } from '@angular/common';
import { Platform } from '@angular/cdk/platform';
import { Router } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { ViewEncapsulation } from '@angular/core';


@Component({
  selector: 'app-single-entry',
  templateUrl: './single-entry.component.html',
  styleUrls: ['./single-entry.component.scss'],
  encapsulation: ViewEncapsulation.None,
  animations: [
    trigger('myInsertRemoveTrigger', [
        transition(':enter', [
          style({ opacity: 0 }),
          animate('300ms', style({ opacity: 1 })),
        ]),
        transition(':leave', [
          animate('300ms', style({ opacity: 0 }))
        ])
    ])
]
})
export class SingleEntryComponent implements OnInit {
    rawBlogEntry: any;
    blogEntry: any;
    swipeCoord: [number, number];
    swipeTime: any;
    id: string;
    currentIndex: number = 0;
    currentSlide = null;
    screenHeight: number;
    screenWidth: number;
    public ip_address: string = "";
    public showModal: boolean = false;
    comment_name: string = '';
    comment_email: string = '';
    comment_text: string = '';

    constructor(
        private httpClient: HttpClient,
        private authService: AuthService,
        private location: Location,
        private route: ActivatedRoute,
        private router: Router,
        public platform: Platform,
        public titleService: Title,
    )
    {
        this.authService.user_authenticated.subscribe((x)=>{
            console.log("auth service")
        });
    }

    ngOnInit() {

        if (this.blogEntry == null)
        {
            this.route.paramMap.subscribe(params => {
                this.id = params.get("id");
                this.getEntry(this.id);
            });
        }
        else
        {
            this.rawBlogEntry = this.blogEntry.raw_entry;
        }
        this.getIPAddress();
}

    @HostListener('window:resize', ['$event'])
    getScreenSize(event?) {
        this.screenHeight = window.innerHeight;
        this.screenWidth = window.innerWidth - 8; // 8 is the width of the scrollbar
    }

    onClick(event) {
        if (!(this.platform.ANDROID || this.platform.IOS))
        {
            if (event.target.localName == "div" && event.target.classList.contains("img-slides"))
            {
                this.closeModal();
            }
            else if (event.target.localName == "img" && event.target.classList.contains("ng-star-inserted"))
            {
                if (event.offsetX < (event.srcElement.width/2))
                {
                    this.previousImage();
                }
                else
                {
                    this.nextImage();
                }
            }
            else if (event.target.localName == 'img' && event.target.className == 'blog' && event.target.getAttribute('src-full') != null)
            {
                let src = event.target.currentSrc;
                let found = this.blogEntry.images.findIndex(image => image.src == src);
                this.openModal(found);
            }
            else if (event.target.localName == 'img' && event.target.parentNode.tagName == 'A' && event.target.parentNode.getAttribute('routerlink') != null)
            {
                if (event.target.parentNode.getAttribute('routerlink').startsWith('/gallery'))
                {
                    let href = event.target.parentNode.getAttribute('routerlink');
                    href = href.replace('/gallery/', '');
                    this.router.navigate(['gallery', href]);
                }
            }
            else if (event.target.localName == 'a' && event.target.getAttribute('routerlink') != null)
            {
                if (event.target.getAttribute('routerlink').startsWith('/gallery'))
                {
                    let href = event.target.getAttribute('routerlink');
                    href = href.replace('/gallery/', '');
                    this.router.navigate(['gallery', href]);
                }
            }
        }
    }

    getEntry(id) {
        this.httpClient.get("https://ng.renner.se/blog.api.php?id=" + id).subscribe((data)=>{
            this.blogEntry = [];
            let tmp_data: any = data;
            this.rawBlogEntry = data[0];

            for(let entry of tmp_data)
            {
                let parser = new DOMParser();
                let parsedHtml = parser.parseFromString(entry.entry, 'text/html');
                var elements = parsedHtml.getElementsByClassName("lightbox");

                for(let j = 0; j < elements.length; j++)
                {
                    let src_full = elements[j].getAttribute("href");
                    elements[j].removeAttribute("href");
                    let children = elements[j].children;

                    for(let k = 0; k < children.length; k++)
                    {
                        if (children[k].getAttribute("src-full") == null)
                        {
                            children[k].setAttribute("src-full", src_full);
                        }
                    }
                }

                tmp_data = parsedHtml.body.innerHTML;
                parsedHtml = parser.parseFromString(tmp_data, 'text/html');

                var photo_links = parsedHtml.getElementsByTagName("a");
                for(let i = 0; i < photo_links.length; i++)
                {
                    // Try to convert old links to the photo gallery
                    let href = decodeURIComponent(photo_links[i].getAttribute('href'));
                    if (href && href.startsWith("/foton/show_dir.php"))
                    {
                        href = href.replace("/foton/show_dir.php?dir=pics/", "");
                        photo_links[i].setAttribute('routerlink', "/gallery/" + href);
                        photo_links[i].removeAttribute("href");
                    }
                }

                let videos = parsedHtml.getElementsByTagName('video');
                for(let i = 0; i < videos.length; i++)
                {
                    let src = videos[i].children[0].getAttribute("src");

                    if (src.startsWith('/'))
                    {
                        src = 'https://renner.se' + src;
                    }
                    videos[i].children[0].setAttribute("src", src);
                }

                let blogImages = [];
                let images = parsedHtml.getElementsByTagName("img");
                for (let i = 0; i < images.length; i++)
                {
                    let src = images[i].src;
                    let src_full = images[i].getAttribute("src-full");
    
                    let src_url = new URL(src);
                    if (src_url.host != 'renner.se')
                    {
                        src = 'https://renner.se' + src_url.pathname;
                    }

                    if (src_full != null)
                    {
                        if (src_full.startsWith('/'))
                        {
                            src_full = src_full.replace('/', 'https://renner.se/');
                        }
                        else
                        {
                            let src_full_url = new URL(src_full);
                            if (src_full_url.host != 'renner.se')
                            {
                                src_full = 'https://renner.se' + src_full_url.pathname;
                            }
                        }
                    }

                    const image = {
                        src: src,
                        src_full: src_full,
                        title: images[i].getAttribute("title"),
                        type: 'image',
                    }
                    blogImages.push(image);
                }

                const blogEntry = {
                    id: entry.id,
                    author: entry.author,
                    date: entry.date,
                    comments: entry.comments,
                    title: entry.title,
                    images: blogImages,
                    active_image: 0,
                    text: parsedHtml.all[0].textContent,
                    previous: entry.previous,
                    next: entry.next,
                    raw_entry: parsedHtml.body.innerHTML.replace(/src="\/pics/g, "src=\"https://renner.se/pics"),
                }

                this.titleService.setTitle(entry.title + " - renner.se");

                this.blogEntry = blogEntry;
            }
        });
    }

    previousEntry(entry) {
    }

    nextEntry(entry) {
    }

    onSwipe(event: TouchEvent, action: String) {
        const coord: [number, number] = [event.changedTouches[0].clientX, event.changedTouches[0].clientY];
        const time = new Date().getTime();

        if (action == 'start')
        {
            this.swipeCoord = coord;
            this.swipeTime = time;
        }
        else if (action == 'end')
        {
            const direction = [coord[0] - this.swipeCoord[0], coord[1] - this.swipeCoord[1]];
            const duration = time - this.swipeTime;

            if (duration < 1000 && Math.abs(direction[0]) > 30 && Math.abs(direction[0]) > Math.abs(direction[1] * 3))
            {
                const swipe = direction[0] < 0 ? 'next' : 'previous';

                if (swipe == 'next')
                {
                    this.nextImage();
                }
                else if (swipe == 'previous')
                {
                    this.previousImage();
                }
            }
        }
    }

    openModal(index): void {
        this.currentIndex = index;
        this.currentSlide = this.blogEntry.images[this.currentIndex];
        if (this.currentSlide.src_full == null)
        {
            this.currentSlide.src_full = this.currentSlide.src;
        }
        document.getElementById('player-container').style.display = "none";
        this.showModal = true;
    }

    closeModal(): void {
        document.getElementById('player-container').style.display = "flex";
        this.showModal = false;
    }

    nextImage()
    {
        this.currentIndex++;
        if (this.currentIndex >= this.blogEntry.images.length)
        {
            this.currentIndex = 0;
        }
        this.currentSlide = this.blogEntry.images[this.currentIndex];

        this.preloadNext();
    }

    preloadNext()
    {
        let next = this.currentIndex + 1;
        if (next >= this.blogEntry.images.length)
        {
            next = 0;
        }

        let img = new Image();
        img.src = this.blogEntry.images[next].src;
    }

    previousImage()
    {
        this.currentIndex--;
        if (this.currentIndex < 0)
        {
            this.currentIndex = this.blogEntry.images.length - 1;
        }
        this.currentSlide = this.blogEntry.images[this.currentIndex];
    }

    onSaveComment()
    {
        let payload = {
            blog_id: this.blogEntry.id,
            ip: this.ip_address,
            name: this.comment_name,
            email: this.comment_email,
            comment: this.comment_text
        };

        this.httpClient.post("https://ng.renner.se/blog.api.php?save-comment", payload).subscribe(
            (response) => {
                console.log(response);
                this.getEntry(this.blogEntry.id);
            },
            (error) => console.log(error)
        );
    }

    onHideComment(id)
    {
        let payload = {
            id: id
        }
        console.log("hiding comment: " + id);
        this.httpClient.post("https://ng.renner.se/blog.api.php?hide-comment", payload).subscribe(
            (response) => {
                console.log(response);
                this.getEntry(this.blogEntry.id);
            },
            (error) => console.log(error)
        );
    }

    onBlockComment(id)
    {
        let payload = {
            id: id
        }
        this.httpClient.post("https://ng.renner.se/blog.api.php?block-comment", payload).subscribe(
            (response) => {
                console.log(response);
                this.getEntry(this.blogEntry.id);
            },
            (error) => console.log(error)
        );
    }

    getIPAddress()
    {
    }

    belongTo(group)
    {
        return this.authService.belongTo(group);
    }

    loggedIn()
    {
        return this.authService.loggedIn();
    }
}
