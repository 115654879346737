import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import * as Highcharts from 'highcharts';
import { HttpClient } from "@angular/common/http";

Highcharts.setOptions({
    title: {
        text: "Temperaturen i Bäckedalen"
    },
    tooltip: {
        xDateFormat: '%Y-%m-%d %H:%M',
    },
    chart: {
        //zoomType: 'x',
        height: "600px",
    },
    xAxis:
    {
        type: 'datetime',
        minorTickWidth: 1,
        minorTickLength: 5,
        gridLineWidth: 1,
        startOnTick: false,
        tickInterval: 1000 * 3600 * 2,
    },
    yAxis: {
        title: {
            text: '°C',
            align: 'high',
            rotation: 0,
            margin: 25,
        },
        showFirstLabel: true,
    }
});

@Component({
  selector: 'app-temperature',
  templateUrl: './temperature.component.html',
  styleUrls: ['./temperature.component.scss']
})
export class TemperatureComponent implements OnInit {
    highcharts: typeof Highcharts = Highcharts;
    updateFlag = false;
    start: String = "";
    chartOptions: Highcharts.Options =
    {
        series: [{
            type: 'spline',
            name: 'ute',
            pointInterval: 60 * 1000,
            data: []
        },
        {
            type: 'spline',
            name: 'jimmy',
            pointInterval: 60 * 1000,
            data: []
        },
        {
            type: 'spline',
            name: 'lego',
            pointInterval: 60 * 1000,
            data: []
        }]
    };

  constructor(
    private route: ActivatedRoute,
    private httpClient: HttpClient
  ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.start = params.get("start");
            this.getTemperatures(this.start);
        });
    }

    getTemperatures(start = null) {
        let args = "";
        if (start != null)
        {
            args = "?start=" + start;
        }
        this.httpClient.get("https://ng.renner.se/temperature.api.php" + args).subscribe(data => {
            this.updateData(data);
        });
    }

    updateData(data) {
        this.chartOptions.series = [{
            type: 'spline',
            name: 'ute',
            pointInterval: 60 * 1000,
            data: data['ute']
        },
        {
            type: 'spline',
            name: 'jimmy',
            pointInterval: 60 * 1000,
            data: data['jimmy']
        },
        {
            type: 'spline',
            name: 'lego',
            pointInterval: 60 * 1000,
            data: data['lego']
        }];

        this.updateFlag = true;
    }
}
