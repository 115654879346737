
<div class="navigation">
    <a (click)="getEntry(blogEntry.previous)" [ngClass]="{'disabled': blogEntry?.previous == ''}" class="navigation">
        <mat-icon >navigate_before</mat-icon>
    </a>
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    <a (click)="getEntry(blogEntry?.next)" [ngClass]="{'disabled': blogEntry?.next == ''}" class="navigation">
        <mat-icon>navigate_next</mat-icon>
    </a>
</div>
<app-entry [blogEntry]="blogEntry"></app-entry>
<div class="comments-container">
    <mat-card appearance="outlined" class="comment" *ngFor="let comment of blogEntry?.comments">
        <div class="blog-header">
            <mat-card-subtitle>{{ comment.name }}</mat-card-subtitle>
            <mat-card-subtitle>
                <span class="material-icons" (click)="onBlockComment(comment.id)" *ngIf="belongTo('admin')">block</span>
                <span class="material-icons" (click)="onHideComment(comment.id)" *ngIf="belongTo('admin')">delete</span>
            </mat-card-subtitle>
        </div>
        <mat-card-subtitle>{{ comment.date }}</mat-card-subtitle>
        <mat-card-content (click)="onClick($event)">
            <div [innerHtml]="comment.comment" class="comment-text"></div>
        </mat-card-content>
    </mat-card>
    <mat-card appearance="outlined" class="comment" *ngIf="loggedIn()">
        <mat-card-title>Kommentera</mat-card-title>
        <mat-card-content>
            <mat-form-field class="comment">
                <mat-label>Namn</mat-label>
                <input matInput type="text" name="comment-name" [(ngModel)]="comment_name">
            </mat-form-field><br>
            <mat-form-field class="comment">
                <mat-label>E-mail</mat-label>
                <input matInput type="text" name="comment-e-mail" [(ngModel)]="comment_email">
            </mat-form-field><br>
            <mat-form-field class="comment text">
                <textarea matNativeControl name="comment-text" [(ngModel)]="comment_text"></textarea>
            </mat-form-field><br>
            <button mat-raised-button color="primary" class="blog-save" (click)="onSaveComment()">Spara</button>
        </mat-card-content>
    </mat-card>
</div>
