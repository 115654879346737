import { Component, OnInit } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';


@Component({
  selector: 'app-add',
  templateUrl: './add.component.html',
  styleUrls: ['./add.component.scss']
})
export class AddComponent implements OnInit {
    private base_url = "https://ng.renner.se/album.api.php?";

    releases: any = [];
    msg = "";
    constructor(private httpClient: HttpClient) { }

    ngOnInit(): void {
    }

    search(mb_discid)
    {
        this.releases = [];
        this.msg = "";
        let service_url = this.base_url + "search&cdid=" + mb_discid;
        this.httpClient.get(service_url).subscribe(
            (data) => {
                this.releases = data;
            },
            (error) => {
                this.msg = "MBID not found :-("
            }
        );
    }

    save(release)
    {
        let service_url = this.base_url + "add";

        this.httpClient.post(service_url, release).subscribe(
            (reponse) => {
                // Do something when record is saved
                this.releases = [];
                this.msg = "Record stored";
            },
            (error) => {
                this.releases = [];
                if (error == "Conflict")
                {
                    this.msg = "Record already exists";
                }
                else
                {
                    this.msg = "Couldn't store record";
                }
            }
        );

    }

    handleEnterKeyPress(event, mbdid)
    {
        this.search(mbdid);
        return false;
    }
}
