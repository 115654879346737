<div class="outer-container">
    <mat-card>
        <mat-card-header>
            <mat-card-title>
                Gör din egen kalender
            </mat-card-title>
            <mat-card-subtitle>
                En ny version av programmet men kalendrarna ska nog vara ungefär som innan.
            </mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            Allt är nu flyttat över till det nya ramverket. Allt borde fungera men det kan säkert finnas lite barnsjukdomar kvar. Kontakta mig gärna på <a href="mailto:kalender@renner.se" class="blog">kalender&#64;renner.se</a>.<br>
            Nu är allt skrivet i javascript, vilket gör att allt köra i browsern, dvs ingen data skickas till mig alls.<br>
            I den gamla versionen så gick det att göra en massa inställningar för bilderna. Det sker nu grafiskt istället. Jag hoppas att det ska vara enklare att använda.<br>
            <br>
            <mat-form-field>
                <mat-label>Year</mat-label>
                <mat-select [(value)]="selectedYear"> 
                    <mat-option *ngFor="let year of years" [value]="year">{{ year }}</mat-option>
                </mat-select>
            </mat-form-field>

            <div class="layout-container">
                <div>
                    <h2><label id="layout-label">Layout</label></h2>
                    <mat-radio-group
                    aria-labelledby="layout-label"
                    class="layout-radio-group"
                    [(ngModel)]="layout">
                        <mat-radio-button *ngFor="let layout of layouts" value="{{ layout.value }}" (change)="setMaxColumns($event)" (click)="setLayoutThumbnail(layout.thumbnail, layout.thumb_large)" [disabled]="!layout.enabled">{{ layout.name }}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div class="layout-thumbnail-container">
                    <img *ngIf="layoutThumbnail" id="layout-image" src="app/calendar/creator/assets/{{ layoutLargeThumbnail }}" alt="Source" height="300px">
                </div>
            </div>
            <div *ngIf="layout == 'namelist' || layout == 'namelist_tall' || layout == 'namelist_landscape' || layout == 'namelist_saddle_stitch'">
                <h2>Kolumner</h2>
                <div class="columns-label-container">
                    <label id="columns-label" class="columns-label">Value</label>
                    <label class="columns-value-label">{{columns.value}}</label>
                </div>
                <mat-slider
                    class="columns-slider"
                    min="1" 
                    [max]="max"
                    step="1"
                    showTickMarks="true"
                    (change)="setCelebrationsColumns($event)">
                    <input matSliderThumb [(ngModel)]="namelist_columns" #columns>
                </mat-slider>
            </div>
            <mat-form-field>
                <mat-label>Typsnitt</mat-label>
                <mat-select [(ngModel)]="font" (selectionChange)="loadFont();"> 
                    <mat-option *ngFor="let font of available_fonts" [value]="font">{{ font.name }}</mat-option>
                </mat-select>
            </mat-form-field>
            <h2>Alternativ</h2>
            <section class="options-section">
                <mat-label>Årtal vid månadsnamn (ex. Januari 2025)</mat-label>
                <mat-checkbox [(ngModel)]="show_year_with_name_of_month"></mat-checkbox>
            </section>
            <section class="options-section">
                <mat-label>Helgdagar</mat-label>
                <mat-checkbox [(ngModel)]="show_holidays"></mat-checkbox>
                <mat-label>Flaggdagar</mat-label>
                <mat-checkbox [(ngModel)]="show_flagdays"></mat-checkbox>
                <mat-label>Namnsdagar</mat-label>
                <mat-checkbox [(ngModel)]="show_namesdays"></mat-checkbox>
                <mat-label>Månfaser</mat-label>
                <mat-checkbox [(ngModel)]="show_moon_phases"></mat-checkbox>
            </section>
            <h2>Färger</h2>
            <section>
                <label for="colour_weekdays" class="colour_picker_label">Veckodagar</label>
                <input type="color" class="colour_picker" name="colour_weekdays" id="colour_weekdays" [(ngModel)]="colour_weekdays">
                <label for="colour_weeknumbers" class="colour_picker_label">Veckonummer</label>
                <input type="color" class="colour_picker" name="colour_weeknumbers" id="colour_weeknumbers" [(ngModel)]="colour_weeknumbers">
                <label for="colour_grid" class="colour_picker_label">Rutnät</label>
                <input type="color" class="colour_picker" name="colour_grid" id="colour_grid" [(ngModel)]="colour_grid">
                <button mat-raised-button (click)="colourPickerReset()">Återställ</button>
                <br>
                <div *ngIf="layout == 'namelist' || layout == 'namelist_tall' || layout == 'namelist_landscape' || layout == 'namelist_saddle_stitch'">
                    <mat-label>Bakgrundsfärg</mat-label>
                    <mat-checkbox [(ngModel)]="use_background_colors"></mat-checkbox>
                </div>
            </section>
            <br>
            <div class="row" *ngIf="layout != 'namelist' && layout != 'namelist_tall' && layout != 'namelist_landscape' && layout != 'namelist_saddle_stitch' && layout != 'kenneth'">
                <h2>Bilder</h2>
                <div class="month" *ngFor="let month of months; let i = index">
                    <label>{{ month.name }}</label><br>
                    <input hidden type="file" accept="image/png, image/jpeg, image/webp, image/bmp" id="{{ month.name }}" name="{{ month.name}}" (change)="onFileSelected($event, i, month.id)"/>
                    <button mat-raised-button (click)="imageSelectClick(month.name)">Välj bild</button>
                    <br>
                    <img class='month' *ngIf="month.file" [src]="month.file.objectUrl" id="{{ month.id }}" alt="Source" (click)="editImage(i, month.id)"/>
                    <mat-card *ngIf="!month.file"  appearance="outlined" class="placeholder" (click)="imageSelectClick(month.name)" id="{{ month.id }}">
                        <mat-card-content>
                            3 : 2
                        </mat-card-content>
                    </mat-card>
                </div>
                <div class="container-cropper" id="container-cropper">
                    <image-cropper
                        [imageChangedEvent]="imageChangedEvent"
                        [maintainAspectRatio]="true"
                        [aspectRatio]="3 / 2"
                        format="jpeg"
                        [cropper] ="cropper"
                        [canvasRotation]="canvasRotation"
                        [(transform)]="transform"
                        allowMoveImage="true"
                        [style.display]="showCropper ? null : 'none'"
                        backgroundColor="white"
                        (imageCropped)="imageCropped($event)"
                        (imageLoaded)="imageLoaded($event)"
                        (cropperReady)="cropperReady()"
                        (loadImageFailed)="loadImageFailed()">
                    </image-cropper>
                    <div id="imageControlButtons" class="image-control-buttons">
                        <button (click)="zoomOut()">Zoom -</button> <button (click)="zoomIn()">Zoom +</button><button (click)="resetImage()">Reset image</button>
                    </div>
                </div>
            
            </div>
            <div>
                <b>Bemärkelsedagar (el. dyl):</b>
                <div *ngFor="let f of [].constructor(celebrations_columns); let r = index">
                    <input *ngIf="celebrations_columns > 1" type="text" placeholder="Namn" [(ngModel)]="listNames[r]">
                    <br>
                    <textarea class="celebrations" [placeholder]="celebrations_placeholder[r] ? celebrations_placeholder[r] : ''" [(ngModel)]="celebrations[r]"></textarea>
                </div>
            </div>  
            <br>
            <br>
            <button mat-raised-button (click)="generatePDF()">Create Calendar</button>
        </mat-card-content>
    </mat-card>
    <div class="container">&nbsp;
    </div>
</div>