import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Component({
    selector: 'app-sunrise-today',
    templateUrl: './sunrise-today.component.html',
    styleUrls: ['./sunrise-today.component.scss']
})
export class SunriseTodayComponent implements OnInit {
    some_sunrises: any = [];

    constructor(
        private httpClient: HttpClient,
    ) { }

    ngOnInit(): void {
        this.getSunrise();
    }

    getSunrise() {
        this.httpClient.get("https://ng.renner.se/sun_time.api.php").subscribe((data)=>{
            this.some_sunrises = data;
        });
    }
}
