<div *ngIf="year" class="year-container">
    <div class="year-header"><mat-icon (click)="getYear(year.year - 1)">navigate_before</mat-icon><div class="year">{{ year.year }}</div><mat-icon (click)="getYear(year.year + 1)">navigate_next</mat-icon></div>
    <div class="month">
        <div *ngFor="let month of year.months" class="days">
            <div class="header">{{ month.month_name.toLowerCase() }}</div>
            <div>&nbsp;</div>
            <div class="day-header">M</div>
            <div class="day-header">T</div>
            <div class="day-header">O</div>
            <div class="day-header">T</div>
            <div class="day-header">F</div>
            <div class="day-header">L</div>
            <div class="day-header red">S</div>

            <ng-container *ngFor="let day of month.days">
                <div *ngIf="day.weekday == 1 || day.day_of_month == 1" class="week-number">{{ day.week }}</div>
                <ng-container *ngIf="day.day_of_month == 1">
                    <div *ngFor="let i of [].constructor(day.weekday - 1);" class="day"></div>
                </ng-container>
                <div  [ngClass]="{'red' : day.weekday == 7 || day.is_holiday, 'underline' : day.is_holiday || day.is_special_day || day.is_flagday}" matTooltip="{{day.description}}" matTooltipClass="tooltipClass" class="day">
                    {{ day.day_of_month }}
                </div>
            </ng-container>
        </div>
    </div>
</div>