import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-month',
  templateUrl: './month.component.html',
  styleUrls: ['./month.component.scss']
})
export class MonthComponent implements OnInit {
    public month: any;
    public currentYear: number = 0;
    public currentMonth: number = 0;
    public currentDay: number = 0;
    
    constructor(
        private httpClient: HttpClient,
    ) { }

    ngOnInit(): void {
        this.getMonth();
    }

    getMonth() {
        this.httpClient.get("https://renner.se/calendar/api/month.php").subscribe((data)=>{
            this.month = data;
            this.getCurrentDate();
        });
    }

    getPreviousYear() {
        this.getOtherMonth(this.month.year - 1, this.month.month);
    }

    getNextYear() {
        this.getOtherMonth(parseInt(this.month.year) + 1, this.month.month);
    }

    getPreviousMonth() {
        if (this.month.month == 1)
        {
            this.getOtherMonth(this.month.year - 1, 12);
        }
        else
        {
            this.getOtherMonth(this.month.year, this.month.month - 1);
        }
    }

    getNextMonth() {
        if (this.month.month == 12)
        {
            this.getOtherMonth(parseInt(this.month.year) + 1, 1);
        }
        else
        {
            this.getOtherMonth(this.month.year, parseInt(this.month.month) + 1);
        }
    }

    getOtherMonth(year, month) {
        this.httpClient.get("https://renner.se/calendar/api/month.php?year=" + year + "&month=" + month).subscribe((data)=>{
            this.month = data;
            this.getCurrentDate();
        });
    }

    getCurrentDate()
    {
            this.currentYear = new Date().getFullYear();
            this.currentMonth = new Date().getMonth() + 1;
            this.currentDay = new Date().getDate();

    }

    isToday(day)
    {
        return (this.month.year == this.currentYear && this.month.month == this.currentMonth && day == this.currentDay);
    }
}
