import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-namesday',
  templateUrl: './namesday.component.html',
  styleUrls: ['./namesday.component.scss']
})
export class NamesdayComponent implements OnInit {
    names: any = [];

    constructor(
        private httpClient: HttpClient,
    ) { }

    ngOnInit(): void {
        this.getNamesdays();
    }

    getNamesdays() {
        this.httpClient.get("https://renner.se/calendar/api/namesdays.php").subscribe((data)=>{
            this.names = data;
        });
    }
}
