<mat-card appearance="outlined" class="widget">
    <mat-card-title class="card-title">Kalendar</mat-card-title>
    <mat-card-content>
        <div class="month">
            <div class="days">
                <div class="prev-year navigation" (click)="getPreviousYear()">&lt;&lt;</div>
                <div class="year-header">{{ month?.year }}</div>
                <div class="next-year navigation" (click)="getNextYear()">&gt;&gt;</div>
                <div class="prev-month navigation" (click)="getPreviousMonth()">&lt;&lt;</div>
                <div class="month-header">{{ month?.month_name.toLowerCase() }}</div>
                <div class="next-month navigation" (click)="getNextMonth()">&gt;&gt;</div>
                <div>&nbsp;</div>
                <div class="day-header">M</div>
                <div class="day-header">T</div>
                <div class="day-header">O</div>
                <div class="day-header">T</div>
                <div class="day-header">F</div>
                <div class="day-header">L</div>
                <div class="day-header red">S</div>
                <ng-container *ngFor="let day of month?.days">
                    <div *ngIf="day.weekday == 1 || day.day_of_month == 1" class="week-number">{{ day.week }}</div>
                    <ng-container *ngIf="day.day_of_month == 1">
                        <div *ngFor="let i of [].constructor(day.weekday - 1);" class="day"></div>
                    </ng-container>
                    <div  [ngClass]="{'red' : day.weekday == 7 || day.is_holiday, 'underline' : day.is_holiday || day.is_special_day || day.is_flagday}" matTooltip="{{day.description}}" matTooltipClass="tooltipClass" class="day">
                        {{ day.day_of_month }}
                    </div>
                </ng-container>
                </div>
        </div>
    </mat-card-content>
</mat-card>
