import { Injectable } from '@angular/core';
import { min } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

    constructor() { }

    storePlaylist(tracks) {
        window.localStorage.setItem('playlist', JSON.stringify(tracks));
    }

    getPlaylist() {
        var tracks = [];
        var storage_data = window.localStorage.getItem('playlist');
        if (storage_data !== undefined)
        {
            tracks = JSON.parse(storage_data);
        }

        return tracks;
    }

    storePlayerCurrentTime(currentTime) {
        window.localStorage.setItem('player-current-time', JSON.stringify(currentTime));
    }

    getPlayerCurrentTime() {
        var currentTime = 0;
        var storage_data = window.localStorage.getItem('player-current-time');
        if (storage_data !== undefined)
        {
            currentTime = JSON.parse(storage_data);
        }

        return currentTime;
    }

    storePlayerActiveTrack(track) {
        window.localStorage.setItem('player-active-track', JSON.stringify(track));
    }

    getPlayerActiveTrack():any {
        var track = {};
        var storage_data = window.localStorage.getItem('player-active-track');
        if (storage_data !== undefined && storage_data != "undefined")
        {
            track = JSON.parse(storage_data);
        }

        return track;
    }

    storeIndexOfActiveTrack(index) {
        window.localStorage.setItem('player-index-of-active-track', JSON.stringify(index));
    }

    getIndexOfActiveTrack() {
        var index = 0;
        var storage_data = window.localStorage.getItem('player-index-of-active-track');
        if (storage_data !== undefined)
        {
            index = JSON.parse(storage_data);
        }

        return index;
    }

    storeStreamState(stream_state) {
        window.localStorage.setItem('player-stream-state', JSON.stringify(stream_state));
    }

    getStreamState() {
        var stream_state;
        var storage_data = window.localStorage.getItem('player-stream-state');
        if (storage_data !== undefined)
        {
            stream_state = JSON.parse(storage_data);
        }

        return stream_state;
    }

    storePlayerMinimized(minimized) {
        window.localStorage.setItem('player-minimized', JSON.stringify(minimized));
    }

    getPlayerMinimized()
    {
        var minimized;
        var storage_data = window.localStorage.getItem('player-minimized');
        if (storage_data !== undefined)
        {
            minimized = JSON.parse(storage_data);
        }

        return minimized;
    }
}
