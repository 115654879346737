import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Title } from '@angular/platform-browser';


@Component({
    selector: 'app-movie',
    templateUrl: './author.component.html',
    styleUrls: ['./author.component.scss']
})
export class AuthorComponent implements OnInit {
    author_books: any;
    currentId: number;

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private route: ActivatedRoute,
        private titleService: Title
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.currentId = parseInt(params.get('id'), 10);
            this.getAuthorBooks(this.currentId);
        });
    }

    getAuthorBooks(id=1) {
        this.httpClient.get("https://ng.renner.se/book.api.php?author-books&id=" + id).subscribe((data)=>{
            this.author_books = data;
            this.setTitle(this.author_books.author.name);
        });
        this.currentId = id;
    }

    setTitle(newTitle: string)
    {
        this.titleService.setTitle(newTitle);
    }

    sortBooksByYear()
    {
        let books = [];
        if (this.author_books && this.author_books.books)
        {
            books = this.author_books.books.sort((a, b) => {
                return a.year - b.year;
            });
        }
        return books;
    }
}
