import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';
import { Title } from '@angular/platform-browser';


export interface Actor {
    actor_id: number;
    name: string;
    role: string;
}

export interface Director {
    id: number;
    name: string;
}

export interface Genre {
    id: number;
    category: string;
}

export interface Format {
    format_id: number;
    format: string;
}

export interface Movie {
    id: number;
    title: string;
    original_title: string;
    length: string;
    price: string;
    bought: string;
    year: string;
    region: string;
    media: string;
    formats: Format[];
    cover: string;
    cover_url: string;
    directors: Director[];
    genres: Genre[];
    actors: Actor[];
    next: number;
    previous: number;
}

@Component({
    selector: 'app-movie',
    templateUrl: './actor.component.html',
    styleUrls: ['./actor.component.scss']
})
export class ActorComponent implements OnInit {
    actor_movies: any;
    currentId: number;
    displayedColumnsActors: string[] = ['name', 'role'];

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
        private route: ActivatedRoute,
        private titleService: Title
    ) { }

    ngOnInit(): void {
        this.route.paramMap.subscribe(params => {
            this.currentId = parseInt(params.get('id'), 10);
            this.getActorMovies(this.currentId);
        });
    }

    getActorMovies(id=1) {
        this.httpClient.get("https://ng.renner.se/movie.api.php?actor-movies&id=" + id).subscribe((data)=>{
            this.actor_movies = data;
            this.setTitle(this.actor_movies.director.name);
        });
        this.currentId = id;
    }

    setTitle(newTitle: string)
    {
        this.titleService.setTitle(newTitle);
    }

    sortMoviesByYear()
    {
        let movies = [];
        if (this.actor_movies && this.actor_movies.movies)
        {
            movies = this.actor_movies.movies.sort((a, b) => {
                return a.year - b.year;
            });
        }
        return movies;
    }
}
