import { AuthService } from '../../services/auth.service';
import { Component, OnInit } from '@angular/core';
import { HttpClient } from "@angular/common/http";

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss']
})
export class CommentsComponent implements OnInit {
    public comments: any;

    constructor(
        private authService: AuthService,
        private httpClient: HttpClient,
    ) { 
        this.authService.user_authenticated.subscribe((x)=>{
            this.getEntries();
        });
    }

    ngOnInit(): void {
    }

    getEntries()
    {
        this.httpClient.get("https://ng.renner.se/blog_comments.api.php").subscribe((data)=>{
            this.comments = data;
        });
    }
}
