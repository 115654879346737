import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ScrollDetectionService } from '../../services/scroll-detection.service';


@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
    books: any;
    screenHeight: number;
    screenWidth: number;
    lastScrollTop: number = 0;
    headerTop: string = "0px";

    constructor(
        private httpClient: HttpClient,
        private route: ActivatedRoute,
        private titleService: Title,
        private scrollService: ScrollDetectionService,
    ) {}

    ngOnInit(): void {
        this.getBooks();

        this.scrollService.getScrollUpdate().subscribe(direction => {
            switch(direction)
            {
                case "up":
                    this.headerTop = "0px";
                    break;
                case "down":
                    this.headerTop = "-110px";
                    break;
            }
        });
    }

    getBooks() {
        this.httpClient.get("https://ng.renner.se/book.api.php?news").subscribe((data)=>{
            this.books = data;
        });
    }

    setTitle(newTitle: string)
    {
        this.titleService.setTitle(newTitle);
    }

    onScroll(event) {
        let st = event.target.scrollTop;

        if (st > this.lastScrollTop)
        {
            this.headerTop = "-110px";
        }
        else
        {
            this.headerTop = "0px";
        }
        this.lastScrollTop = st;
    }

    scrollToTop() {
        var element = document.getElementById('container');
        element.scrollTop = 0;
    }
}
