import { FormGroupDirective } from '@angular/forms';
import { NgForm } from '@angular/forms';
import { UntypedFormControl } from '@angular/forms';
import { UntypedFormGroup } from '@angular/forms';
import { Validators } from '@angular/forms';
import { Component, OnInit, Inject } from '@angular/core';
import { AuthService } from '../services/auth.service';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-user-login',
  templateUrl: './user-login.component.html',
  styleUrls: ['./user-login.component.scss']
})
export class UserLoginComponent implements OnInit {

    public error: any;

    loginForm = new UntypedFormGroup({
        username: new UntypedFormControl('', [
            Validators.required
        ]),
        password: new UntypedFormControl('', [
            Validators.required
        ])
    });
    constructor(
        private authService: AuthService,
        private dialogRef: MatDialogRef<UserLoginComponent>,
        @Inject(MAT_DIALOG_DATA) public data
    ) { }

    public closeMe()
    {
        this.dialogRef.close();
    }

    ngOnInit(): void {
    }

    login() {
        this.authService.authenticate({ username: this.loginForm.value.username, password: this.loginForm.value.password})
        .subscribe(
            res => {
                this.authService.loginUser(res.jwt, res.token);
                this.closeMe();
            },
            err => {
                this.error = err.error.message;
            }
        );
    }
}
